import React, { useState } from "react";
import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import { motion } from 'framer-motion';
import './DeliveryCheck.css';

const DeliveryCheck = () => {
    const [zipCode, setZipCode] = useState("");
    const [isInServiceArea, setIsInServiceArea] = useState(null);
    const [userLocation, setUserLocation] = useState(null);
    const [showForm, setShowForm] = useState(null);

    const serviceAreaCoords = [
        { lat: 37.328218, lng: -122.467106 },
        { lat: 37.504915, lng: -122.563236 },
        { lat: 37.793056, lng: -122.545383 },
        { lat: 37.826690, lng: -122.414920 },
        { lat: 37.714879, lng: -122.338016 },
        { lat: 37.586578, lng: -122.266605 },
        { lat: 37.364258, lng: -121.902994 },
        { lat: 37.227555, lng: -121.974937 },
        { lat: 37.255939, lng: -122.117384 },
        { lat: 37.328218, lng: -122.467106 },
    ];

    const handleZipCodeSubmit = (e) => {
        e.preventDefault();

        const validZipCodes = ["94587","95035","94128","90505","94061",
            "94403","94018","94401","94545","95125","95118","95043",
            "93930","93012","95131","95127","95002","95020","94002",
            "94402","91301","95076","95023","90277","92154","93450",
            "94116","95004","94112","93908","91320","93451","90732",
            "95037","94304","94306","94044","94030","94074","90290",
            "90263","93065","92106","90265","91361","91932","93453",
            "93954","94501","93040","95032","95014","95133","95123",
            "95046","93015","94040","94005","90274","94019","94037",
            "94060","95033","95075","95045","90275","95113","95030",
            "94305","94010","94070","93254","93252","93060","93446",
            "93021","93023","93210","94132","94127","94121","93461",
            "91360","91362","93066","91377","93925","94560","94555",
            "94538","95119","94014","94025","94021","95148","91302",
            "95124","95116","95110","95122","95138","95129","95054",
            "94022","94028","94080","94063","94065","94020","94027",
            "94038","95112","95136","95121","95111","95135","95120",
            "95070","94024","95132","95134","94015","94062","94404",
            "90731","94066","94130","94122"
        ];
        if (validZipCodes.includes(zipCode)) {
            setIsInServiceArea(true);
        } else {
            setIsInServiceArea(false);
        }
    }

    const handleUseLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ lat: latitude, lng: longitude});

                    const isInArea = checkLocationInServiceArea(latitude, longitude);
                    setIsInServiceArea(isInArea);
                },
                () => alert("Unable to retrieve your location")
            );
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const checkLocationInServiceArea = (lat, lng) => {
        const serviceAreaZipCodes = ["94102", "94013", "94019"];
        return serviceAreaZipCodes.includes(zipCode);
    };

    return (
        <div className="delivery-check">
            <h2>Check if You're in Our Delivery Area</h2>

            <div className="map-and-form-container">
                {/* Display map of service area */}
                <div className="map-container">
                    <LoadScript googleMapsApiKey="AIzaSyC7zupNPTeAKyCzAlQLrWt0Cm2dxfPMA5s">
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '400px' }}
                            center={{ lat: 37.4827, lng: -122.2322 }}
                            zoom={9}
                            >
                            <Polygon
                                paths={serviceAreaCoords}
                                options={{
                                    fillColor: "#2196F3",
                                    fillOpacity: 0.35,
                                    strokeColor: "#2196F3",
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                }}
                                />
                        </GoogleMap>
                    </LoadScript>
                </div>
                
                {/* Form on the Right */}
                <div className="form-container">
                    <form onSubmit={handleZipCodeSubmit}>
                        <label>
                            Enter Your Zip Code:
                            <input
                                type="text"
                                value={zipCode}
                                onChange={(e) => setZipCode(e.target.value)}
                                placeholder="Enter Zip Code"
                                required
                            />
                        </label>
                        <button type="submit">Check Delivery Area</button>
                    </form>
                    <button onClick={handleUseLocation}>Use My Location</button>

                    {/* Handle Results */}
                    <div>
                        {isInServiceArea === true && (
                            <motion.div
                                initial={{ opacity: 0, scale: 0.8}}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.3, ease: "easeOut" }} 
                                className="alert alert-success"
                            >
                                <h3>We're in your Neighborhood!</h3>
                                <a href="/checkout" className="cta-button">Order Now</a>
                            </motion.div>
                        )}

                        {isInServiceArea === false &&(
                            <motion.div 
                                initial={{ opacity: 0, scale: 0.8}}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.3, ease: "easeOut" }} 
                                className="alert alert-info"
                            >
                                <h3>We're not in your area yet, but stay tuned!</h3>
        {/*                    <form>
                                    <label>
                                        Enter your email:
                                        <input type="email" placeholder="johnnyappleseed@gmail.com" required />
                                    </label>
                                    <button type="submit">Notify Me</button>
                                </form> */}
                            </motion.div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeliveryCheck;
