import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import NavBar from "./NavBar";
import ScrollToTop from "./ScrollToTop";
import DeliveriesPage from "./HowItWorks";  // Eager load home route
import './LoadingFallback.css';

import './LoadingFallback.css';
import './CheckoutPage.css';
import './NavBar.css';
import './HomePage.css';
import './SubscriptionProducts.css';
import './CreateAccount.css';
import './Login.css';
import './UserPage.css';
import './CustomizeDelivery.css';
import './AdminPage.css';
import './HowItWorks.css';
import './ReviewCarousel.css';
import './ProduceCarousel.css';
import './PictureGrid.css';

// Lazy load non-home routes
const HomePage = lazy(() => import("./HomePage"));
const SubscriptionProducts = lazy(() => import("./SubscriptionProducts"));
const CreateAccount = lazy(() => import("./CreateAccount"));
const Login = lazy(() => import("./Login"));
const UserPage = lazy(() => import("./UserPage"));
const CustomizeDeliveryPage = lazy(() => import("./CustomizeDelivery"));
const AdminPage = lazy(() => import("./AdminPage"));
const CheckoutPage = lazy(() => import("./CheckoutPage"));

// Loading fallback component
const LoadingFallback = () => (
  <div className="loading-page">
    <div className="loading-content">
      <h1>Loading...</h1>
      <div className="spinner"></div>
    </div>
  </div>
);

function MainApp() {
  const location = useLocation();
  const hideNavbarRoutes = ['/create-account', '/login'];
  const shouldHideNavbar = hideNavbarRoutes.includes(location.pathname);

  return (
    <div>
      {!shouldHideNavbar && <NavBar />}
      <ScrollToTop />
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          <Route path="/about-us" element={<HomePage />} />
          <Route path="/subscriptions" element={<SubscriptionProducts />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/login" element={<Login/>} />
          <Route path="/dashboard" element={<UserPage/>} />
          <Route path="/" element={<DeliveriesPage />} /> {/* Home route loaded eagerly */}
          <Route path="/customize" element={<CustomizeDeliveryPage />} />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
      </Suspense>
    </div>
  );
}

function App() {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}

export default App;