import React, { lazy, Suspense } from 'react';
import './HowItWorks.css';
import DeliveryCheck from './DeliveryCheck';

const DeliveriesPage = () => {
    const steps = [
        {
            image: "https://storage.googleapis.com/about-video/images/subscription-image.jpg",
            alt: "Choose Subscription",
            title: "Step 1: Choose Your Subscription",
            description: "One Simple Price, Watch Your Grocery Bills Drop:<br/><br/>Weekly Delivery:<br/> Reduce your grocery spend by $2,080/year<br/><br/>Biweekly Delivery:<br/> Cut your grocery costs by $1,040/year<br/><br/>Monthly Delivery:<br/> Lower your grocery bill by $480/year",
            priority: true
        },
        {
            image: "https://storage.googleapis.com/about-video/images/produce-image.jpg",
            alt: "Pick Produce",
            title: "Step 2: Customize your Box",
            description: "We primarily focus on California & Organic Farm-to-Table Fruits and Vegetables to be included in your Produce delivery.<br/><br/> You are able to provide customization requests at any time through your Vita Verde Account.",
            priority: true
        },
        {
            image: "https://storage.googleapis.com/about-video/images/source-produce.jpg",
            alt: "Source Fresh Produce",
            title: "Step 3: We Source Fresh Produce",
            description: "We have partnered with countless local farms in order to ensure your eating the freshest stuff in California!",
            priority: false
        },
        {
            image: "https://storage.googleapis.com/about-video/images/delivery-image.jpg",
            alt: "Delivery",
            title: "Step 4: Delivered to Your Doorstep",
            description: "Your Farm-to-Table delivery is dropped off at your desired location at a convenient time on Thursday!",
            priority: false
        },
        {
            image: "https://storage.googleapis.com/about-video/images/enjoy-image.jpg",
            alt: "Enjoy and Reorder",
            title: "Step 5: Enjoy",
            description: "Enjoy saving Time & Money on produce that you would only be able to find at a local farm, With out any of the added stress!",
            priority: false
        }
    ];

    const StepCard = ({ step }) => (
        <div className="card-container">
            <div className="card">
                <img 
                    src={step.image} 
                    alt={step.alt}
                    loading={step.priority ? "eager" : "lazy"}
                    width="400"
                    height="300"
                    decoding="async"
                />
                <h2>{step.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: step.description }} />
            </div>
        </div>
    );

    return (
        <>
            <div className="deliveries-container">
                <section className='top-section'>
                    <h1>Fuel Your Family For Greatness</h1>
                    <a href="/checkout">
                        <button className='button-83' role='button'>
                            Start Saving on Farm to Table Produce Now
                        </button>
                    </a>
                </section>

                <section className="deliveries-steps">
                    {steps.map((step, index) => (
                        <StepCard key={index} step={step} />
                    ))}
                </section>
            </div>
            <section className='cta-container'>
                <a href="/checkout">
                    <button className='button-83' role='button'>
                        Start Saving on Farm to Table Produce Now
                    </button>
                </a>
            </section>
        </>
    );
};

export default DeliveriesPage;